import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import apimanage from "../src/utils/apimanage";
import SaveLocal from "../src/utils/SaveLocal";
import Isempty from "@/utils/isempty";
import sroapi from "@/utils/apimanage";
import '../src/assets/css/reset.css'
import TDesign from 'tdesign-vue';
// 引入组件库全局样式资源
import 'tdesign-vue/es/style/index.css';


//引入element-ui
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';

import './plugins/vant.js'
//引入vant
import Vant from 'vant';
import 'vant/lib/index.css';
import {Loading,Toast} from "vant";
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)
Vue.use(Loading)
Vue.use(Toast)
Vue.use(Vant);
Vue.use(TDesign);



//全局引入echarts

//需要挂载到Vue原型上
Vue.prototype.$echarts = echarts
Vue.use(ElementUI);
Vue.prototype.$http=apimanage;
Vue.prototype.$saveLocal=SaveLocal;
Vue.prototype.$isEmpty=Isempty;
Vue.prototype.$sroapi=sroapi
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
