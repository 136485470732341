import axios from 'axios'
import SaveLocal from '../utils/SaveLocal'
import { Message } from 'element-ui'
import { Toast } from 'vant'
// 统一请求路径前缀
// const base = '/ccma-server/api'   // 服务器地址/
// export const base = 'https://test.bms-learning.com.cn' // 本地
export const base = 'https://api.bms-learning.com.cn'   // 线上
// export const base = 'http://localhost:21021'   // 线上
// export const base = 'http://api.ec.wterp.cn' // 豪哥测试

// 超时设定
axios.defaults.timeout = 60 * 1000

axios.interceptors.request.use(
	config => {
		return config
	},
	err => {
		Message.error('请求超时')
		return Promise.reject(err)
	}
)

// http response 拦截器
axios.interceptors.response.use(
	response => {
		const data = response.data
		// 根据返回的code值来做不同的处理(和后端约定)
		switch (data.code) {
			case 401:
				// 未登录 清除已登录状态
				SaveLocal.set('accessToken', '')
				SaveLocal.set('encryptedAccessToken', '')
				if (data.msg !== null) {
					Message.error(data.msg)
				} else {
					Message.error('未知错误，请重新登录')
					// this.router.push('/login')
					window.location.href = 'https://smusxath.bms.com/affwebservices/public/saml2sso?SPID=iwinv3p'
				}
				// this.router.push('/login')
				window.location.href = 'https://smusxath.bms.com/affwebservices/public/saml2sso?SPID=iwinv3p'
				break
			case 403:
				// 没有权限
				if (data.msg !== null) {
					// Message.error(data.msg)
				} else {
					Message.error('未知错误')
				}
				break
			case 500:
				// 错误
				if (data.msg !== null) {
					Message.error(data.msg)
				} else {
					Message.error('未知错误')
				}
				break
			default:
				return data
		}
		return data
	},
	err => {
		const code = err.response.status
		switch (code) {
			case 401:
				// 未登录 清除已登录状态
				SaveLocal.set('accessToken', '')
				// router.push('/')
				window.location.href = 'https://smusxath.bms.com/affwebservices/public/saml2sso?SPID=iwinv3p'
				break
			case 403:
				// 没有权限
				if (err.response.data.error.message !== null) {
					// Message.error(err.response.data.error.message)
					Toast(err.response.data.error.message)
				} else {
					Message.error('未知错误')
				}
				break
			case 500:
				// 错误
				if (err.response.data.error.message !== null) {
					// Message.error(err.response.data.error.message)
					Toast(err.response.data.error.message)
				} else {
					Message.error('未知错误')
				}
				break
			default:
				if (err.response.data.error.message !== null) {
					// Message.error(err.response.data.error.message)
					Toast(err.response.data.error.message)
				} else {
					// Message.error('未知错误')
					Toast('未知错误')
				}
				return err
		}
		return Promise.resolve(err)
	}
)

export const getRequest = (url, params) => {
	const accessToken = SaveLocal.get('accessToken')
	const encryptedAccessToken = SaveLocal.get('encryptedAccessToken')
	return axios({
		method: 'get',
		url: `${base}${url}`,
		params: params,
		headers: {
			authorization: `Bearer ` + accessToken,
			encryptedAccessToken: `Bearer ` + encryptedAccessToken
		}
	})
}

export const postRequest = (url, params) => {
	const accessToken = SaveLocal.get('accessToken')
	const encryptedAccessToken = SaveLocal.get('encryptedAccessToken')
	return axios({
		method: 'post',
		url: `${base}${url}`,
		data: params,
		headers: {
			'Content-Type': 'application/json',
			authorization: `Bearer ` + accessToken,
			encryptedAccessToken: `Bearer ` + encryptedAccessToken
		}
	})
}

export const putRequest = (url, params) => {
	const accessToken = SaveLocal.get('accessToken')
	const encryptedAccessToken = SaveLocal.get('encryptedAccessToken')
	return axios({
		method: 'put',
		url: `${base}${url}`,
		data: params,
		transformRequest: [
			function (data) {
				let ret = ''
				for (const it in data) {
					ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
				}
				return ret
			}
		],
		headers: {
			'Content-Type': 'application/json',
			authorization: `Bearer ` + accessToken,
			encryptedAccessToken: `Bearer ` + encryptedAccessToken
		}
	})
}

export const deleteRequest = (url, params) => {
	const accessToken = SaveLocal.get('accessToken')
	const encryptedAccessToken = SaveLocal.get('encryptedAccessToken')
	return axios({
		method: 'delete',
		url: `${base}${url}`,
		params: params,
		headers: {
			authorization: `Bearer ` + accessToken,
			encryptedAccessToken: `Bearer ` + encryptedAccessToken
		}
	})
}

export const importRequest = (url, params) => {
	const accessToken = SaveLocal.get('accessToken')
	const encryptedAccessToken = SaveLocal.get('encryptedAccessToken')
	return axios({
		method: 'post',
		url: `${base}${url}`,
		data: params,
		headers: {
			authorization: `Bearer ` + accessToken,
			encryptedAccessToken: `Bearer ` + encryptedAccessToken
		}
	})
}

export const uploadFileRequest = (url, params) => {
	const accessToken = SaveLocal.get('accessToken')
	const encryptedAccessToken = SaveLocal.get('encryptedAccessToken')
	return axios({
		method: 'post',
		url: `${base}${url}`,
		params: params,
		headers: {
			authorization: `Bearer ` + accessToken,
			encryptedAccessToken: `Bearer ` + encryptedAccessToken
		}
	})
}
