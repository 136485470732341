import { getRequest, postRequest } from "../utils/api";
import { encryptDes } from "@/utils/Encryption_Des";

let sroapi = {}

//登录
sroapi.Authenticate = (data) => {
    return postRequest('/api/TokenAuth/Authenticate', data)
}
//BSM登录
sroapi.AuthenticateByBMSId = (data) => {
    return postRequest('/api/TokenAuth/AuthenticateByBMSId', data)
}

//根据用户BU获取大类以及第一个大类的小分类
sroapi.GetMaterialsClass = () => {
    return getRequest('/api/services/app/StudyMaterialsService/GetMaterialsClass')
}

//获取对应BU下的热门课程
sroapi.GetMaterialsByRecommendList = (parms) => {
    return getRequest('/api/services/app/StudyMaterialsService/GetMaterialsByRecommendList', parms)
}

//获取列表(全部数据)
sroapi.GetMaterialsList = (params) => {
    let url = `/api/services/app/StudyMaterialsService/GetMaterialsList`
    Object.keys(params).forEach((key, i) => {
        if (i === 0) return url += `?${key}=${params[key]}`
        key === "Ids" ? url += params[key] : url += `&${key}=${params[key]}`
    })
    return getRequest(url)
}

//获取列表 (部分数据)
sroapi.GetMaterialsList_once = (params) => {
    return getRequest(`/api/services/app/StudyMaterialsService/GetMaterialsList`, params)
}

//查看资料详情
sroapi.GetMaterialsDetail = (parms) => {
    return getRequest('/api/services/app/StudyMaterialsService/GetMaterialsDetail', parms)
}

//获取用户的评价记录
sroapi.GetUserStarLog = (parms) => {
    return getRequest('/api/services/app/StudyMaterialsService/GetUserStarLog', parms)
}

//保存点击记录
sroapi.SetStudyMaterialsLog = (parms) => {
    return postRequest(`/api/services/app/StudyMaterialsService/SetStudyMaterialsLog?input=${encryptDes(parms)}`)
}

//保存评分
sroapi.SetStudyMaterialsStar = (parms) => {
    return postRequest(`/api/services/app/StudyMaterialsService/SetStudyMaterialsStar?input=${encryptDes(parms)}`)
}
//保存观看时长
sroapi.GetUpdateLearningLog = (parms) => {
    return postRequest('/api/services/app/StudyMaterialsService/UpdateLearningLog', parms)
}

//查询是否在收藏夹中
sroapi.GetFavouriteById = (parms) => {
    return getRequest('/api/services/app/StudyMaterialsService/GetFavouriteById', parms)
}

//添加/移除收藏夹
sroapi.SetFavourite = (parms) => {
    return postRequest(`/api/services/app/StudyMaterialsService/SetFavourite?input=${encryptDes(parms)}`)
}

//获取记录表信息，若无则创建
sroapi.GetOrCreateLearningLog = (parms) => {
    return getRequest(`/api/services/app/StudyMaterialsService/GetOrCreateLearningLog?input=${encryptDes(parms)}`)
}

//-----------------------在线考试-----------------------------
//获取可进行的考试
sroapi.GetExamList = () => {
    return getRequest('/api/services/app/ExamService/GetExamList')
}

//根据考试Id获取考试详情
sroapi.GetExamById = (parms) => {
    return getRequest('/api/services/app/ExamService/GetExamById', parms)
}

//开始考试
sroapi.GetStartExam = (parms) => {
    return postRequest('/api/services/app/ExamService/StartExam', parms)
}

//保存答题
sroapi.GetSaveAnswerInfo = (parms) => {
    return postRequest(`/api/services/app/ExamService/SaveAnswerInfo?input=${encryptDes(parms)}`)
}

//根据ID获取考试小结
sroapi.GetExamLog = (parms) => {
    return getRequest('/api/services/app/ExamService/GetExamLog', parms)
}

//根据用户考试记录获取考试分类
sroapi.GetExamType = () => {
    return getRequest('/api/services/app/ExamService/GetExamType')
}

//根据考试type获取考试列表
sroapi.GetExamLogListByType = (parms) => {
    return getRequest('/api/services/app/ExamService/GetExamLogListByType', parms)
}

//根据考试ID获取考试详细记录
sroapi.GetExamLogDetailByExamId = (parms) => {
    return getRequest('/api/services/app/ExamService/GetExamLogDetailByExamId', parms)
}

//根据详情ID后期题目解析
sroapi.GetExamLogDetailQuestion = (parms) => {
    return getRequest('/api/services/app/ExamService/GetExamLogDetailQuestion', parms)
}

// 重新计算分数
sroapi.TryComputeScore = (parms) => {
    return postRequest('/api/services/app/ExamService/TryComputeScore', { input: parms })
}

//-----------------------培训计划-----------------------------
//培训计划
sroapi.GetTrain = (parms) => {
    return getRequest('/api/services/app/TrainService/GetTrain', parms)
}

//学习计划
sroapi.GetCheckpoint = (parms) => {
    return getRequest('/api/services/app/TrainService/GetCheckpoint', parms)
}

//获取资料列表
sroapi.GetDataList = (parms) => {
    return getRequest('/api/services/app/TrainService/GetDataList', parms)
}

//获取资料详情
sroapi.GetData = (parms) => {
    return getRequest('/api/services/app/TrainService/GetData', parms)
}

//获取考试
sroapi.GetTest = (parms) => {
    return getRequest('/api/services/app/TrainService/GetTest', parms)
}

//提交考试
sroapi.InsTest = (parms) => {
    return postRequest('/api/services/app/TrainService/InsTest', parms)
}

//获取进度
sroapi.GetSpeed = (parms) => {
    return getRequest('/api/services/app/TrainService/GetSpeed', parms)
}

//获取学习报告
sroapi.GetReport = (parms) => {
    return getRequest('/api/services/app/TrainService/GetReport', parms)
}

//保存资料在线时长
sroapi.SaveDataInfoTime = (parms) => {
    return postRequest('/api/services/app/TrainService/SaveDataInfoTime', parms)
}

//分组
sroapi.Grouping = (parms) => {
    return postRequest(`/api/services/app/TrainService/Grouping?input=${encryptDes(parms)}`)
}

//收藏题目或取消收藏
sroapi.CollectionTest = (parms) => {
    return postRequest('/api/services/app/TrainService/CollectionTest', parms)
}

//获取我的学习报告
sroapi.GetMyReport = (parms) => {
    return getRequest('/api/services/app/TrainService/GetMyReport', parms)
}

//用户信息
sroapi.GetUserInfo = (parms) => {
    return getRequest('/api/services/app/TrainService/GetUserInfo', parms)
}

//排行榜
sroapi.GetGroupDetailRank = (parms) => {
    return getRequest('/api/services/app/TrainService/GetGroupDetailRank', parms)
}

//我的---考试记录
sroapi.GetTestGrades = (parms) => {
    return getRequest('/api/services/app/TrainService/GetTestGrades', parms)
}

//考试详情
sroapi.GetTestGradesLog = (parms) => {
    return getRequest('/api/services/app/TrainService/GetTestGradesLog', parms)
}

//我的收藏
sroapi.GetTestCollection = (parms) => {
    return getRequest('/api/services/app/TrainService/GetTestCollection', parms)
}

//魔力值记录
sroapi.GetGoldCoinLog = (parms) => {
    return getRequest('/api/services/app/TrainService/GetGoldCoinLog', parms)
}

//id传（沃迎新生id）
sroapi.GetStrategyImg = (parms) => {
    return getRequest('/api/services/app/TrainService/GetStrategyImg', parms)
}

//收藏查询
sroapi.GetFavouriteByIdpx = (parms) => {
    return getRequest('/api/services/app/TrainService/GetFavouriteById', parms)
}
//添加/取消收藏
sroapi.SetFavouritepx = (parms) => {
    return postRequest('/api/services/app/TrainService/SetFavourite', parms)
}

//个人中心以及主页接口
//----------------------------------------------------------------------------

//查询首页Banner
sroapi.GetBanner = (parms) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetBanner', parms)
}

//查询学习报告
sroapi.GetLearningReport = (parms) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetLearningReport', parms)
}

//我的任务
sroapi.GetTask = (parms) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetNewTask', parms)
}

//我的团队培训
sroapi.GetMyTeam = (params) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetMyTeam', params)
}

// 团体任务详情
sroapi.GetTaskByBmsId = (params) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetTaskByBmsId', params)
}

//我的收藏
sroapi.GetCollection = (parms) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetCollection', parms)
}

//魔力值信息
sroapi.GetPoints = (parms) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetPoints', parms)
}
//近一周足迹
sroapi.GetTracks = (parms) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetTracks', parms)
}

// 注册用户
sroapi.CreateOrUpdateUserInfo = (parms) => {
    return postRequest('/api/services/app/UserInfoService/CreateOrUpdateUserInfo', parms)
}

// 查询结业报告
sroapi.QueryGradReport = (parms) => {
    return postRequest('/api/services/app/TrainService/QueryGradReport', parms)
}

// 发送邮件
sroapi.SendEmailByMaterialsData = (parms) => {
    return postRequest('/api/services/app/UserInfoService/SendEmailByMaterialsData', parms)
}

//调研
sroapi.GetSummary = (parms) => {
    return getRequest('/api/services/app/TrainService/GetSummary', parms)
}
// 提交调研
sroapi.InsSummary = (parms) => {
    return postRequest('/api/services/app/TrainService/InsSummary', parms)
}

sroapi.UploadCover = (parms) => {
    return postRequest('/api/services/app/TrainAdminService/UploadCover', parms)
}

// --------------------------------------------------------------------------------------------------------勋章
//根据培训项目Id和BmsId查看已获得的勋章列表
sroapi.GetUserMedalByTrainIdAndBmsId = (parms) => {
    return getRequest('/api/services/app/TrainService/GetUserMedalByTrainIdAndBmsId', parms)
}
//更新头像
sroapi.SetAvatar = (parms) => {
    return postRequest('/api/services/app/TrainService/SetAvatar', parms)
}
//更新签名
sroapi.SetSignName = (parms) => {
    return postRequest('/api/services/app/TrainService/SetSignName', parms)
}
//根据培训Id获取个人排行榜
sroapi.GetRangkingByTrainClassId = (parms) => {
    return getRequest('/api/services/app/TrainService/GetRangkingByTrainClassId', parms)
}
//根据培训Id获取团队排行榜
sroapi.GetRangkingByTrainClassIdForGroup = (parms) => {
    return getRequest('/api/services/app/TrainService/GetRangkingByTrainClassIdForGroup', parms)
}
//获取首页排行榜(当前月)
sroapi.GetHomeRanking = (parms) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetHomeRanking')
}
// 获取攻擂守擂列表
sroapi.GetArenaList = (parms) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetArenaList', parms)
}

// 根据Id获取攻擂守擂排行榜以及魔力值
sroapi.GetArenaUsersById = (parms) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetArenaUsersById', parms)
}

// 根据Id获取 攻擂守擂战 关联的考试以及规则
sroapi.GetArenaForExamDetail = (parms) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetArenaForExamDetail', parms)
}

// 根据Id获取 攻擂守擂战 关联的AIPractice以及规则
sroapi.GetArenaForAIPractice = (parms) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetArenaForAIPractice', parms)
}

// 根据分组名字获取总排行榜
sroapi.GetArenaUsersByGroupName = (parms) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetArenaUsersByGroupName', parms)
}

// 根据id获取 小组旁行榜
sroapi.GetArenaUsersByFlag = (parms) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetArenaUsersByFlag', parms)
}

// 获取道具列表
sroapi.GetPropList = (parms) => {
    return getRequest('/api/services/app/PropMallService/GetPropList', parms)
}

// 魔力值商城抽奖
sroapi.PrizeDraw = (parms) => {
    return postRequest('/api/services/app/PropMallService/PrizeDraw', parms)
}

// 魔力值明细
sroapi.GetPointsLogs = (parms) => {
    return getRequest('/api/services/app/PropMallService/GetPointsLogs', parms)
}

// 兑换道具
sroapi.ExchangeProp = (parms) => {
    return postRequest('/api/services/app/PropMallService/ExchangeProp', parms)
}

// 获取我的道具列表
sroapi.GetMyProps = (parms) => {
    return getRequest('/api/services/app/PropMallService/GetMyProps', parms)
}

// 获取道具使用记录
sroapi.GetUsePropLogs = (parms) => {
    return getRequest('/api/services/app/PropMallService/GetUsePropLogs', parms)
}

// 根据id获取道具信息
sroapi.GetPropById = (parms) => {
    return getRequest('/api/services/app/PropMallService/GetPropById', parms)
}

// 权益卡使用、橡皮擦
sroapi.UseProp = (parms) => {
    return postRequest('/api/services/app/PropMallService/UseProp', parms)
}

// 获取抽奖列表
sroapi.GetPropDraw = (parms) => {
    return getRequest('/api/services/app/PropMallService/GetPropDraw', parms)
}

// 获取自己BU
sroapi.GetMyBU = (parms) => {
    return getRequest('/api/services/app/PropMallService/GetMyBU', parms)
}

// 根据Email精确搜索同Bu下的用户
sroapi.GetBuUsersByEmailAsybc = (parms) => {
    return getRequest('/api/services/app/PropMallService/GetBuUsersByEmailAsybc', parms)
}

// 攻擂守擂使用道具
sroapi.attUseProp = (parms) => {
    return postRequest('/api/services/app/HomeAndPersonalCenterService/UseProp', parms)
}


// 根据email精确搜索用户
sroapi.GetUsersByEmailAsybc = (parms) => {
    return getRequest('/api/services/app/PropMallService/GetUsersByEmailAsybc', parms)
}

// 获取积分明细
sroapi.GetScoreDetail = (parms) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetScoreDetail', parms)
}

// 根据管理员设置的转化率把积分转换成魔力值
sroapi.ExchangeScore2PointByArenaIdAndUser = (parms) => {
    return postRequest('/api/services/app/HomeAndPersonalCenterService/ExchangeScore2PointByArenaIdAndUser', parms)
}

//查询企业微信参数
sroapi.GetQywxAgentConfig = (parms) => {
    return getRequest('/api/services/app/HomeAndPersonalCenterService/GetQywxAgentConfig',parms)
}

export default sroapi