import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		//判断首页遮罩层是否出现
		selectShow: 0,
		widthView: document.body.clientWidth,
		userId: 0,
		//总分
		totalScore: 0,
		//剩余时间
		surplusTime: 0,
		xingxingStatenum: 0,
		//总分评分
		totalScoreState: 0,
		//课件内容
		coursewareContent: 0,
		//专业程度
		professionalLevel: 0,
		//轻易程度
		easyLevel: 0,
		//搜索内容
		inputhome: '',
		homeinquire: 0,
		//点击小分类获取列表-测试课包接口
		materialsList: [],
		// 小分类
		smallClass: [],
		// 大分类
		bigClass: [],
		// 大分类index
		bigSelect: 0,
		// 小分类index
		smallSelect: 0,
		// 个人中心左边Select
		personalCenterLeft: 0,
		// 个人中心右边边Select
		personalCenterRight: 0,
		// 移动端分类选中项
		currentIndex: '',
		// 遮罩层
		maskLayerFlag: false,
		LearningReportList: [],
		// 考试记录分类index
		stateIndex: 0
	},
	getters: {
		// bigClassNameCom: state => {
		//   return state.bigClass[state.bigSelect - 1].name
		// },
		// smallClassNameCom: state =>{
		//   return state.smallClass[state.smallSelect].name
		// }
	},
	mutations: {
		// 改变小分类
		SetSmallClass(store, smallClass) {
			store.smallClass = smallClass
		},
		// 改变小分类索引
		SetSmallSelect(store, smallSelect) {
			store.smallSelect = smallSelect
		},
		// 改变个人中心左边
		SetPersonalCenterLeft(store, personalCenterLeft) {
			store.personalCenterLeft = personalCenterLeft
		},
		// 改变个人中心右边
		SetPersonalCenterRight(store, personalCenterRight) {
			store.personalCenterRight = personalCenterRight
		},
		// 改变大分类
		SetBigClass(store, bigClass) {
			store.bigClass = bigClass
		},
		// 改变大分类索引
		SetBigSelect(store, bigSelect) {
			store.bigSelect = bigSelect
		},
		// 改变移动端分类选中项
		SetCurrentIndex(store, currentIndex) {
			store.currentIndex = currentIndex
		},
		// setmaskLayerFlag
		SetMaskLayerFlag(store, maskLayerFlag) {
			store.maskLayerFlag = maskLayerFlag
		},
		// LearningReportList
		SetLearningReportList(store, LearningReportList) {
			store.LearningReportList = LearningReportList
		},
		// 考试记录分类index
		SetStateIndex(store, stateIndex) {
      store.stateIndex = stateIndex
    }
	},
	actions: {}
})
