<template>
	<div id="app">
		<div :class="$store.state.maskLayerFlag ? 'mask-layer' : ''"></div>
		<!-- <router-view v-wechat-title="$route.meta.title" /> -->
		<!-- 缓存的页面，缓存$route.meta.keepAlive为true的组件 -->
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive" v-wechat-title="$route.meta.title"></router-view>
		</keep-alive>
		<!-- 不缓存的页面，不缓存$route.meta.keepAlive为false的组件 -->
		<router-view v-if="!$route.meta.keepAlive" v-wechat-title="$route.meta.title"></router-view>
	</div>
</template>

<script src="./utils/adjust-android-size.js" type="text/javascript"></script>
<script>
export default {
	name: 'App',
	data() {
		return {
			browser: ''
		}
	},
	methods: {},
	mounted() {}
}
</script>

<style lang="less">
.mask-layer {
	background-color: rgba(1, 1, 1, 0.8);
	height: 100vh;
	width: 100vw;
	position: fixed;
	z-index: 100;
}
* {
	padding: 0;
	margin: 0;
}

html,
body,
#app {
	height: 100%;
	// 禁止左右滑动
	touch-action: pan-y;
	-webkit-text-size-adjust: 100% !important;
	text-size-adjust: 100% !important;
	-moz-text-size-adjust: 100% !important;
	//-webkit-text-size-adjust: none !important;
	// cursor: pointer;

	//设置思源黑体
	//font-family: Noto Sans SC;
	//font-style: normal;
	//font-weight: 400;
}

/* //I0S下在app.vue中的样式下写入这行代码可解决ios显示问题记住一 定不要加scoped */
//body {
//  -webkit-text-size-adjust: 100% !important;
//  text-size-adjust: 100% !important;
//  -moz-text-size-adjust: 100% !important;
//  //-webkit-text-size-adjust: none !important;
//}

//body{
//  -webkit-text-size-adjust: 100% !important;
//}

//#app {
//  font-family: Noto Sans SC;
//  font-style: normal;
//}
.cur-p {
	cursor: pointer;
	/*鼠标变小手*/
}
</style>
