import CryptoJS from 'crypto-js'

/**
 * Des 加密
 * @param {*} data 加密数据
 * @param {string} key (加密解密需要的key,与后端约定'EcacheDe')
 */
function encryptDes(data) {
  let params
  if (typeof data === 'object') {
    params = JSON.stringify(data)
  } else {
    params = data
  }
  console.log(params, 'params')
  const encryptData = CryptoJS.DES.encrypt(
    params,
    CryptoJS.enc.Utf8.parse('EcacheDe'), // 参数1=密钥, 参数2=加密内容
    {
      mode: CryptoJS.mode.ECB, // 为DES的工作方式
      padding: CryptoJS.pad.Pkcs7 // 当加密后密文长度达不到指定整数倍(8个字节、16个字节)则填充对应字符
    }
  ).toString() // toString=转字符串类型
  return encodeURIComponent(encryptData)
}

/**
 * Des 解密
 * @param {*} data 解密数据
 * @param {string} key (解密解密需要的key,与后端约定'EcacheDe')
 */
function decryptDes(data) {
  const decrypted = CryptoJS.DES.decrypt(
    data, // 若message是base64格式，则无需转16进制hex，直接传入message即可
    CryptoJS.enc.Utf8.parse('EcacheDe'),
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }
  )
  return decrypted.toString(CryptoJS.enc.Utf8)
}

export { encryptDes, decryptDes }
