import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
	//按钮测试样式
	{
		path: '/dropdown',
		name: '',
		component: () => import('../view/personal/components/Dropdowncalendar')
	},
	{ path: '/', redirect: '/home/homepage' },
	{
		path: '/login',
		name: 'login',
		meta: { title: '登录' },
		component: () => import('../view/Login')
	},
	{
		path: '/registered',
		name: 'registered',
		meta: { title: '注册' },
		component: () => import('../view/registered')
	},

	//搜索PC
	{
		path: '/home/SearchPc',
		name: 'SearchPc',
		meta: { title: '搜索' },
		component: () => import('../view/SearchPc')
	},

	{
		path: '/home',
		name: 'home',
		component: () => import('../view/Home'),
		children: [
			//首页
			{
				path: '/home/homepage',
				name: 'homepage',
				meta: { title: '首页' },
				component: () => import('../view/homepage')
			},

			//培训计划
			{
				path: '/home/pcMoteam',
				name: 'pcMoteam',
				meta: { title: '培训计划' },
				component: () => import('../view/personalmove/pcMoteam')
			},
			//资料集锦
			{
				path: '/home/datacollection/index',
				name: 'datacollection',
				meta: { title: '资料集锦' },
				component: () => import('../view/datacollection/index')
			},
			{
				path: '/home/datacollection/thecoursecatalog',
				name: 'thecoursecatalog',
				meta: { title: '课程目录' },
				component: () => import('../view/datacollection/thecoursecatalog')
			},
			//培训项目
			{
				path: '/home/training',
				name: 'training',
				meta: { title: '培训项目' },
				component: () => import('../view/Projecttraining/Index')
			},
			//培训项目考试
			{
				path: '/projectIphoneAnswer',
				name: 'projectIphoneAnswer',
				component: () => import('../view/Projecttraining/data/projectIphoneAnswer')
			},
			//开启学习旅途
			{
				//开启
				path: '/home/open',
				name: 'open',
				meta: { title: '开启学习旅程' },
				component: () => import('../view/Projecttraining/Open')
			},
			{
				path: '/home/open/covisit',
				name: 'covisit',
				component: () => import('../view/co-visit.vue')
			},
			// 成就
			{
				path: '/home/open/achievement',
				name: 'achievement',
				meta: { title: '勋章' },
				component: () => import('../view/achievement/index.vue')
			},
			{
				path: '/home/open/achievement2',
				name: 'achievement2',
				component: () => import('../view/achievement/index2.vue')
			},
			//分组
			{
				path: '/home/open/grouping',
				name: 'grouping',
				meta: { title: '分组' },
				component: () => import('../view/Projecttraining/Grouping')
			},
			//排名规则
			{
				path: '/home/open/ranking',
				name: 'ranking',
				meta: { title: '排名规则' },
				component: () => import('../view/Projecttraining/Ranking')
			},
			//活动列表
			{
				path: '/home/open/activity',
				name: 'activity',
				meta: { title: '活动列表' },
				component: () => import('../view/Projecttraining/Activitylist')
			},
			//结束
			{
				path: '/home/open/Complete',
				name: 'Complete',
				meta: { title: '回望学习旅程' },
				component: () => import('../view/Projecttraining/Complete')
			},
			//资料列表
			{
				path: '/home/open/datalist',
				name: 'datalist',
				meta: { title: '资料列表' },
				component: () => import('../view/Projecttraining/Datalist')
			},
			//学习报告
			{
				path: '/home/open/learning',
				name: 'openlearning',
				meta: { title: '学习报告' },
				component: () => import('../view/Projecttraining/Learning')
			},
			//班级排名
			{
				path: '/home/open/classranking',
				name: 'classranking',
				meta: { title: '班级排名' },
				component: () => import('../view/Projecttraining/Classranking')
			},
			{
				//移动端班级魔力值排名
				path: '/home/open/manaClassrank',
				name: 'manaClassrank',
				meta: { title: '魔力值排行榜' },
				component: () => import('../view/manaClassrank')
			},
			{
				//移动端班级排名
				path: '/home/open/Classranknow',
				name: 'Classranknow',
				meta: { title: '排行榜' },
				component: () => import('../view/Projecttraining/Classranknow')
			},
			//考试规则
			{
				path: '/home/rule',
				name: 'rule',
				meta: { title: '考试规则' },
				component: () => import('../view/online/Rule')
			},
			//在线考试
			{
				path: '/home/online',
				name: 'online',
				meta: { title: '在线考试' },
				component: () => import('../view/online/Index')
			},
			//测试小结
			{
				path: '/home/summary',
				name: 'summary',
				meta: { title: '答题小结' },
				component: () => import('../view/online/Summary')
			},

			// 考试小结(通过未通过合并)
			{
				path: '/home/merge',
				name: 'merge',
				meta: { title: '考试小结' },
				component: () => import('../view/online/Merge')
			},
			//考试小结-通过
			{
				path: '/home/summaryone',
				name: 'summaryone',
				meta: { title: '答题小结' },
				component: () => import('../view/online/Summaryone')
			},
			//考试小结-未通过
			{
				path: '/home/summarytwo',
				name: 'summarytwo',
				meta: { title: '答题小结' },
				component: () => import('../view/online/Summarytwo')
			},
			// 评估表
			{
				path: '/home/evaluationForm',
				name: 'evaluationForm',
				meta: { title: '评估表' },
				component: () => import('../view/evaluationForm.vue')
			},
			//考试答题
			{
				path: '/home/answer',
				name: 'answer',
				meta: { title: '答题' },
				component: () => import('../view/online/Answer')
			},
			{
				path: '/home/undertakingPc',
				name: 'undertakingPc',
				meta: { title: '知悉承诺书' },
				component: () => import('../view/undertakingPc.vue')
			},
			//答题解析
			{
				path: '/home/analysis',
				name: 'analysis',
				meta: { title: '答题解析' },
				component: () => import('../view/online/Analysis')
			},
			{
				path: '/goldCoinShopPC',
				name: 'goldCoinShopPC',
				meta: { title: '魔力值商城' },
				component: () => import('../view/goldCoinShop.vue')
			},
			{
				path: '/myProps',
				name: 'myProps',
				meta: { title: '我的道具' },
				component: () => import('../view/myProps/myProps.vue')
			},
			{
				path: '/pointsShopping',
				name: 'pointsShopping',
				meta: { title: '魔力值明细' },
				component: () => import('../view/myProps/pointsShopping.vue')
			},
			{
				path: '/myPropsDetails',
				name: 'myPropsDetails',
				meta: { title: '道具说明' },
				component: () => import('../view/myProps/propsDetails.vue')
			},
			{
				path: '/attackDefend',
				name: 'attackDefend',
				meta: { title: '攻擂守擂' },
				component: () => import('../view/attackDefend/index.vue')
			},
			{
				path: '/attackDefendDetailsPC',
				name: 'attackDefendDetailsPC',
				meta: { title: '查看榜单' },
				component: () => import('../view/attackDefend/details')
			},
			{
				path: '/attackProintDetailPC',
				name: 'attackProintDetailPC',
				meta: { title: '积分明细' },
				component: () => import('../view/attackDefend/prointDetail.vue')
			},
			{
				path: '/attackDefendDetailsAll',
				name: 'attackDefendDetailsAll',
				meta: { title: '总榜单详情' },
				component: () => import('../view/attackDefend/listAll.vue')
			},
			{
				//个人中心
				path: '/home/personal',
				name: 'personal',
				meta: {
					title: '个人中心'
				},
				component: () => import('../view/personal/Index'),
				children: [
					{
						//我的收藏
						path: '/home/personal/collection',
						name: 'collection',
						meta: { title: '我的收藏' },
						component: () => import('../view/personal/MyCollection')
					},
					{
						//学习报告
						path: '/home/personal/learning',
						name: 'learning',
						meta: {
							title: '学习报告'
						},
						component: () => import('../view/personal/Learning')
					},
					{
						//魔力值信息
						path: '/home/personal/information',
						name: 'information',
						meta: { title: '魔力值信息' },
						component: () => import('../view/personal/Information')
					},
					{
						//我的足迹
						path: '/home/personal/tracks',
						name: 'tracks',
						meta: { title: '我的足迹' },
						component: () => import('../view/personal/MyTracks')
					},
					{
						//累计学习资料
						path: '/home/personal/studyMaterial',
						name: 'learning',
						meta: { title: '累计学习资料' },
						component: () => import('../view/personal/studyMaterial')
					},
					{
						//累计培训项目
						path: '/home/personal/trainingProject',
						name: 'learning',
						meta: { title: '累计培训项目' },
						component: () => import('../view/personal/trainingProject')
					},
					{
						path: '/home/personal/open/achievement',
						name: 'personalAchievement',
						meta: { title: '勋章' },
						component: () => import('../view/achievement/index.vue')
					},
					{
						path: '/home/personal/attackDefend',
						name: 'personalAttackDefend',
						meta: { title: '攻擂守擂' },
						component: () => import('../view/attackDefend/index.vue')
					}
				]
			}
		]
	},
	//移动端
	{
		path: '/attackProintDetail',
		name: 'attackProintDetail',
		meta: { title: '积分明细' },
		component: () => import('../view/attackDefend/prointDetail.vue')
	},
	{
		path: '/attackDefendDetails',
		name: 'attackDefendDetails',
		meta: { title: '攻擂守擂详情' },
		component: () => import('../view/attackDefend/details')
	},
	{
		path: '/goldCoinShop',
		name: 'goldCoinShop',
		meta: { title: '魔力值商城' },
		component: () => import('../view/goldCoinShop.vue')
	},
	{
		path: '/movecoll',
		name: 'movecoll',
		meta: { title: '我的收藏' },
		component: () => import('../view/personalmove/Movecoll')
	},
	{
		path: '/undertaking',
		name: 'undertaking',
		meta: { title: '知悉承诺书' },
		component: () => import('../view/undertaking.vue')
	},

	{
		path: '/questionnaire',
		name: 'questionnaire',
		meta: { title: '问卷' },
		component: () => import('../view/questionnaire.vue')
	},
	//我的收藏
	{
		path: '/movelearning',
		name: 'movelearning',
		meta: {
			title: '学习报告',
			keepAlive: true // 需要缓存
		},
		component: () => import('../view/personalmove/Movelearning')
	}, //学习报告
	{
		path: '/moveinform',
		name: 'moveinform',
		meta: { title: '魔力值信息' },
		component: () => import('../view/personalmove/Moveinform')
	}, //魔力值信息
	{
		path: '/movetrac',
		name: 'movetrac',
		meta: { title: '我的足迹' },
		component: () => import('../view/personalmove/Movetracks')
	}, //我的足迹
	{
		path: '/studyMaterialihpone',
		name: 'movetrac',
		meta: { title: '累计学习资料' },
		component: () => import('../view/personalmove/studyMaterial')
	}, //累计学习资料
	{
		path: '/trainingProjectihpone',
		name: 'movetrac',
		meta: { title: '累计培训项目' },
		component: () => import('../view/personalmove/trainingProject')
	}, //累计培训项目
	{ path: '/moteam', name: 'moteam', component: () => import('../view/personalmove/Moteam') }, //我的团队
	//收藏视频资料PDF等
	{ path: '/collectiphone', name: 'collectiphone', component: () => import('../view/datacollection/Collectiphone') },
	{
		//移动端课程目录
		path: '/thecoursecatalogiphone',
		name: 'thecoursecatalogiphone',
		component: () => import('../view/datacollection/thecoursecatalogiphone')
	},
	//移动端搜索样式
	{
		path: '/searchst',
		name: 'searchst',
		meta: { title: '搜索' },
		component: () => import('../view/Searchst')
	},
	{
		//移动端规则
		path: '/iphonerule',
		name: 'iphonerule',
		meta: { title: '考试规则' },
		component: () => import('../view/online/IphoneRule')
	},
	{
		//移动端答题
		path: '/IphoneAnswer',
		name: 'IphoneAnswer',
		meta: { title: '答题' },
		component: () => import('../view/online/IphoneAnswer.vue')
	},
	{
		//移动端班级排名
		path: '/Classrank',
		name: 'Classrank',
		meta: { title: '排行榜' },
		component: () => import('../view/Projecttraining/Classrank')
	},

	{
		//移动小结1
		path: '/IphoneSummaryone',
		name: 'IphoneSummaryone',
		meta: { title: '答题小结' },
		component: () => import('../view/online/IphoneSummaryone')
	},
	{
		//移动小结1
		path: '/IphoneSummaryone1',
		name: 'IphoneSummaryone1',
		meta: { title: '答题小结' },
		component: () => import('../view/online/IphoneSummaryone1')
	},
	{
		//移动小结1
		path: '/IphoneSummaryone2',
		name: 'IphoneSummaryone2',
		meta: { title: '答题小结' },
		component: () => import('../view/online/IphoneSummaryone2')
	},
	{
		//题目解析
		path: '/Iphonetitle',
		name: 'Iphonetitle',
		meta: { title: '题目解析' },
		component: () => import('../view/online/Iphonetitle')
	},
	{
		//移动搜索结果
		path: '/SearchJg',
		name: 'SearchJg',
		meta: { title: '搜索结果' },
		component: () => import('../view/datacollection/SearchJg')
	},
	{
		//移动搜索结果
		path: '/Testsummary',
		name: 'Testsummary',
		meta: { title: '搜索结果' },
		component: () => import('../view/Projecttraining/Testsummary')
	},
	{
		//移动排名规则
		path: '/rankingrules',
		name: 'rankingrules',
		meta: { title: '排名规则' },
		component: () => import('../view/Projecttraining/rankingrules')
	},
	{
		//移动线上会议
		path: '/onlinemeeting',
		name: 'onlinemeeting',
		meta: { title: '线上会议' },
		component: () => import('../view/Projecttraining/Onlinemeeting')
	},
	//移动端学习报告
	{
		path: '/learningmove',
		name: 'learningmove',
		meta: { title: '学习报告' },
		component: () => import('../view/Projecttraining/Learningmove')
	},
	//开启学习旅途
	{
		path: '/begintolearn',
		name: 'begintolearn',
		meta: { title: '开启学习旅途' },
		component: () => import('../view/Projecttraining/Begintolearn')
	},
	{
		path: '/GroupingIphone',
		name: 'GroupingIphone',
		meta: { title: '分组' },
		component: () => import('../view/Projecttraining/GroupingIphone')
	},
	{
		path: '/activitiesList',
		name: 'activitiesList',
		meta: { title: '活动列表' },
		component: () => import('../view/Projecttraining/activitiesList')
	},
	{
		path: '/ProjecttrainingCollect',
		name: 'ProjecttrainingCollect',
		component: () => import('../view/Projecttraining/data/ProjecttrainingCollect')
	},
	//培训项目资料查看
	{
		path: '/learningReport',
		name: 'learningReport',
		meta: { title: '资料列表' },
		component: () => import('../view/Projecttraining/data/learningReport')
	},
	// //培训项目考试
	// {
	//     path: '/projectIphoneAnswer',
	//     name: 'projectIphoneAnswer',
	//     component: () => import('../view/Projecttraining/data/projectIphoneAnswer')
	// },
	//移动端结业
	{
		path: '/graduation',
		name: 'graduation',
		meta: { title: '回望学习旅程' },
		component: () => import('../view/Projecttraining/graduation')
	},
	{
		path: '/AnswerSummary',
		name: 'AnswerSummary',
		meta: { title: '测试小结' },
		component: () => import('../view/Projecttraining/AnswerSummary')
	},
	//首页
	{
		path: '/home/test',
		name: 'test',
		meta: { title: '测试h5拉起小程序' },
		component: () => import('../view/test')
	}
]
// //修改路由模式
const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
	// scrollBehavior: () => {
	//     history.pushState(null, null, document.URL)
	// }
})

router.beforeEach((to, from, next) => {
	//beforeEach是router的钩子函数，在进入路由前执行
	console.log('to, from, next', to, from, next)
	/*
	if (!_isMobile()) {
		let isLoginIn = localStorage.getItem('accessToken') && localStorage.getItem('BMS_ID')
		// console.log("beforeEach",isLoginIn);
		if (to.path == '/home/homepage' ) {
			if (!isLoginIn) {
				// console.log("beforeEach11",isLoginIn);
				window.location.href = 'https://smusxath.bms.com/affwebservices/public/saml2sso?SPID=iwinv3p'
			}else{
				next()
			}
		} else {
			next()
		}
	} else {
		next()
	}
	*/
	 next()
	if (to.path) {
		//判断是否有标题
		document.title = to.meta.title
	}
	// next()
})
const _isMobile = () => {
	// 获取用户代理字符串
	var userAgent = navigator.userAgent

	// 判断是否是移动端
	var isMobile = /Mobile|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)

	if (isMobile) {
		console.log('当前是移动端')
	} else {
		console.log('当前是电脑端')
	}

	return isMobile
}
export default router
